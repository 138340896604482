import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GoalForm from "../components/GoalForm";
import GoalItem from "../components/GoalItem";
import Spinner from "../components/Spinner";
import { getGoals, reset } from "../features/goals/goalSlice";
import { getSongs } from "../features/songs/songSlice";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPending, setShowPending] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { goals, isLoading, isError, message } = useSelector(
    (state) => state.goals
  );
  // const pendingAlbums = goals.filter(
  //   (album) => album.status === "pending approval by team"
  // );

  const { songs } = useSelector((state) => state.songs);

  const [filteredAlbums, setFilteredAlbums] = useState([]);
  const [filter, setFilter] = useState("All");
  const [search, setSearch] = useState("");

  const [showAll, setShowAll] = useState(false);

  // Dynamically derive the items to display
  const displayedGoals = showAll ? filteredAlbums : filteredAlbums.slice(0, 25);

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      navigate("/login");
      console.log("yeetDash");
    }

    if (user && user.role == "admin") {
      dispatch(getGoals());
      dispatch(getSongs());
    }

    return () => {
      dispatch(reset());
    };
  }, [user]);

  //update filteredAlbums when goals changes
  useEffect(() => {
    // make a mutable copy of goals and sort them by liveDate, newest first
    if (goals?.length > 0) {
      let goalsCopy = [...goals].sort((a, b) => {
        return new Date(a.liveDate) - new Date(b.liveDate);
      });
      setFilteredAlbums(goalsCopy);
    }
  }, [goals]);

  // useEffect(() => {
  //   // console.log("filteredAlbums: " + filteredAlbums[0]?.);
  //   // sort by liveDate, newest first
  //   let goalsCopy = [...filteredAlbums].sort((a, b) => {
  //     return new Date(a.liveDate) - new Date(b.liveDate);
  //   });
  //   setFilteredAlbums(goalsCopy);
  // }, [filteredAlbums]);

  if (!user) {
    return <p>Your session has expired. Please log back in.</p>;
  }

  if (user && user.isApproved == "false") {
    return (
      <p>
        Your account must be approved by a moderator before you can upload
        albums.
      </p>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div
      className="dash"
      style={{
        marginBottom: "4rem",
      }}
    >
      {user && user.role != "admin" && (
        <>
          {/* <p style={{
            fontSize: '1rem',
            marginBottom: '3rem'
          }}>The System Distro is rebranding to Btdrp. The distribution process remains the same.</p> */}
          <section className="heading">
            {/*<h5>Welcome {user && user.name}</h5>*/}
            <p>Submit an Album</p>
          </section>
          <GoalForm user={user} />
        </>
      )}

      {!showPending && user && user.role == "admin" && (
        <section className="content">
          <h1>Albums</h1>
          {user.role != "admin" && <h2>Your Albums</h2>}
          {/* {user.role == "admin" && (
            <h2
              onClick={() => {
                setShowPending(true);
                dispatch(getGoals());
              }}
              style={{ border: "solid 2px black", cursor: "pointer" }}
            >
              Show Pending Albums Only
            </h2>
          )} */}
          {/* Make a search bar */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              style={{
                width: "70%",
                height: "60px",
                fontSize: "24px",
                padding: "10px",
                borderRadius: "10px",
                border: "2px solid #ccc",
                outline: "none",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onChange={(e) => {
                setSearch(e.target.value);

                let goalsCopy = goals;

                let newAlbums = goalsCopy.filter(
                  (goal) =>
                    goal.text
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    // or the goal.artists array contains an object with artistName that includes the search term
                    goal.artists.some((artist) =>
                      artist.artistName
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    ) ||
                    // or accountEmail or accountName includes the search term
                    goal.accountEmail
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    goal.accountName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                );
                if (filter == "All") {
                  setFilteredAlbums(
                    [...newAlbums].sort((a, b) => {
                      return new Date(a.liveDate) - new Date(b.liveDate);
                    })
                  );
                } else if (filter == "Pending") {
                  newAlbums = newAlbums.filter(
                    (goal) =>
                      goal.status.toLowerCase().includes("changes added") ||
                      goal.status
                        .toLowerCase()
                        .includes("pending approval by team")
                  );
                  setFilteredAlbums(
                    [...newAlbums].sort((a, b) => {
                      return new Date(a.liveDate) - new Date(b.liveDate);
                    })
                  );
                } else if (filter != "Custom") {
                  newAlbums = newAlbums.filter((goal) =>
                    goal.status.toLowerCase().includes(filter.toLowerCase())
                  );
                  setFilteredAlbums(
                    [...newAlbums].sort((a, b) => {
                      return new Date(a.liveDate) - new Date(b.liveDate);
                    })
                  );
                } else {
                  newAlbums = newAlbums.filter(
                    //doesn't include pending approval by team, album approved, or rejected
                    (goal) =>
                      !goal.status
                        .toLowerCase()
                        .includes("pending approval by team") &&
                      !goal.status.toLowerCase().includes("album approved") &&
                      !goal.status.toLowerCase().includes("rejected")
                  );
                  setFilteredAlbums(
                    [...newAlbums].sort((a, b) => {
                      return new Date(a.liveDate) - new Date(b.liveDate);
                    })
                  );
                }

                // setFilteredAlbums(newAlbums);
              }}
            />
            <select
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                //filter by search term first
                let goalsCopy = goals;
                // console.log("search: " + search);
                let newAlbums = goalsCopy.filter(
                  (goal) =>
                    goal.text.toLowerCase().includes(search.toLowerCase()) ||
                    // or the goal.artists array contains an object with artistName that includes the search term
                    goal.artists.some((artist) =>
                      artist.artistName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) ||
                    // or accountEmail or accountName includes the search term
                    goal.accountEmail
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    goal.accountName
                      .toLowerCase()
                      .includes(search.toLowerCase())
                );
                if (e.target.value == "All") {
                  setFilteredAlbums(
                    [...newAlbums].sort((a, b) => {
                      return new Date(a.liveDate) - new Date(b.liveDate);
                    })
                  );
                } else if (e.target.value == "Pending") {
                  newAlbums = newAlbums.filter(
                    (goal) =>
                      goal.status.toLowerCase().includes("changes added") ||
                      goal.status
                        .toLowerCase()
                        .includes("pending approval by team")
                  );
                  setFilteredAlbums(
                    [...newAlbums].sort((a, b) => {
                      return new Date(a.liveDate) - new Date(b.liveDate);
                    })
                  );
                } else if (e.target.value != "Custom") {
                  newAlbums = newAlbums.filter((goal) =>
                    goal.status
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                  setFilteredAlbums(
                    [...newAlbums].sort((a, b) => {
                      return new Date(a.liveDate) - new Date(b.liveDate);
                    })
                  );
                } else {
                  newAlbums = newAlbums.filter(
                    //doesn't include pending approval by team, album approved, or rejected
                    (goal) =>
                      !goal.status
                        .toLowerCase()
                        .includes("pending approval by team") &&
                      !goal.status.toLowerCase().includes("album approved") &&
                      !goal.status.toLowerCase().includes("rejected")
                  );
                  setFilteredAlbums(
                    [...newAlbums].sort((a, b) => {
                      return new Date(a.liveDate) - new Date(b.liveDate);
                    })
                  );
                }
              }}
              style={{
                height: "60px",
                fontSize: "24px",
                marginLeft: "10px",
                padding: "10px",
                borderRadius: "10px",
                border: "2px solid #ccc",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <option value="All">All</option>
              <option value="Pending">Pending</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
              <option value="Custom">Custom</option>
            </select>
          </div>
          {displayedGoals.length > 0 ? (
            <div className="goals">
              {displayedGoals.map((goal) => (
                <GoalItem key={goal._id} goal={goal} songs={songs} />
              ))}
            </div>
          ) : (
            <div>
              {user.role != "admin" && <h3>You have not started any albums</h3>}
              {user.role == "admin" && <h3>No albums found</h3>}
            </div>
          )}
          {filteredAlbums.length > 25 && (
            <button
              onClick={() => setShowAll((prev) => !prev)}
              style={{
                padding: "10px 20px",
                fontSize: "18px",
                fontWeight: "bold",
                backgroundColor: showAll ? "#ff6666" : "#4caf50", // Red for "Show Less", Green for "Show All"
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                marginTop: "20px",
                marginBottom: "20px",
                transition: "background-color 0.3s ease", // Smooth color transition
              }}
            >
              {showAll ? "Show Less" : "Show All"}
            </button>
          )}
        </section>
      )}
      {/* {showPending && user && user.role == "admin" && (
        <section className="content">
          <h1>Albums</h1>
          {user.role != "admin" && <h2>Your Albums</h2>}
          {user.role == "admin" && (
            <h2
              onClick={() => {
                setShowPending(false);
                dispatch(getGoals());
              }}
              style={{ border: "solid 2px black", cursor: "pointer" }}
            >
              Show All Albums
            </h2>
          )}
          {goals.length > 0 ? (
            <div className="goals">
              {pendingAlbums.map((goal) => (
                <GoalItem key={goal._id} goal={goal} />
              ))}
            </div>
          ) : (
            <div>
              {user.role != "admin" && <h3>You have not started any albums</h3>}
              {user.role == "admin" && <h3>No pending albums</h3>}
            </div>
          )}
        </section>
      )} */}
    </div>
  );
}

export default Dashboard;
