import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterAdmin from "./pages/RegisterAdmin";
import RegisterArtist from "./pages/RegisterArtist";
import Accounts from "./pages/Accounts";
import Albums from "./pages/Albums";
import Messages from "./pages/Messages";
import Settings from "./pages/Settings";
import Payees from "./pages/Payees";
import Footer from "./components/Footer";
import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   // if the user is on a domain that is not btdrp.com, redirect them to btdrp.com/whatever route they were trying to access
  //   if (window.location.hostname !== "distro.btdrp.com") {
  //     window.location.href = `https://distro.btdrp.com${window.location.pathname}`;
  //   }
  // }, []);

  return (
    <>
      <Router>
        <div className="container">
          <Header />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/register-admin" element={<RegisterAdmin />} />
            <Route path="/register-artist" element={<RegisterArtist />} />
            <Route path="/albums" element={<Albums />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/payees" element={<Payees />} />
          </Routes>
          <Footer />
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
